import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    
    
    <div className="maincontent">
      <h1 className="welcomePage" style={{fontSize:25, margin:0,padding:0, color:`var(--textNormal)`}}>I'm Erin Xue. Welcome to the space.</h1>
      <p className="welcomePage" style={{
        fontSize:17, 
        color:`var(--textNormal)`,
        lineHeight:`25px`
        }}>I love humans as much as I love all the other living things with or without consciousness. (e.g. computers, my cats, coffee beans) 
        <br/> <br/>
        Feel free to take a look around here and maybe read some blogs. I put my social profiles on this site somewhere too, so try to find them!
      </p>
    </div>
    

  </Layout>
)

export default IndexPage
